import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
// const Dashboard = () => import('@/views/Dashboard')
// const Charts = () => import('@/views/charts/Charts')
// const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const inicio = () => import('@/views/registro/inicio')
const datosPersonales = () => import('@/views/registro/datosPersonales')
const referenciaBancaria = () => import('@/views/registro/referenciaBancaria')
const ceneval = () => import('@/views/registro/ceneval')
const ficha = () => import('@/views/registro/ficha')
const cargaDocumentosAceptado = () => import('@/views/registro/cargaDocumentosAceptado')
const referenciaInscripcion = () => import('@/views/registro/referenciaInscripcion')
const cargaMateriasInicio = () => import('@/views/registro/cargaMateriasInicio')

// const Tabs = () => import('@/views/base/Tabs')
// const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
// const Carousels = () => import('@/views/base/Carousels')
// const Collapses = () => import('@/views/base/Collapses')
// const Jumbotrons = () => import('@/views/base/Jumbotrons')
// const ListGroups = () => import('@/views/base/ListGroups')
// const Navs = () => import('@/views/base/Navs')
// const Navbars = () => import('@/views/base/Navbars')
// const Paginations = () => import('@/views/base/Paginations')
// const Popovers = () => import('@/views/base/Popovers')
// const ProgressBars = () => import('@/views/base/ProgressBars')
// const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
// const StandardButtons = () => import('@/views/buttons/StandardButtons')
// const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
// const Dropdowns = () => import('@/views/buttons/Dropdowns')
// const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
// const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
// const Brands = () => import('@/views/icons/Brands')
// const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
// const Alerts = () => import('@/views/notifications/Alerts')
// const Badges = () => import('@/views/notifications/Badges')
// const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const Forgot = () => import('@/views/pages/Forgot')

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)

export default new Router({
  mode: 'hash', 
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  
  return [

    {

      path: '/',
      redirect: '/pages/login',
      name: 'Login',
      component: TheContainer,
    
      children: [
        // {
        //   path: 'dashboard',
        //   name: 'Dashboard',
        //   component: Dashboard
        // },

        // {
        //   path: 'charts',
        //   name: 'Charts',
        //   component: Charts
        // },
        // {
        //   path: 'widgets',
        //   name: 'Widgets',
        //   component: Widgets
        // },

        // {
        //   path: 'users',
        //   meta: {
        //     label: 'Users'
        //   },
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       name: 'Users',
        //       component: Users
        //     },
        //     {
        //       path: ':id',
        //       meta: {
        //         label: 'User Details'
        //       },
        //       name: 'User',
        //       component: User
        //     }
        //   ]
        // },

        {
          path: 'admision',
          redirect: '/admision/inicio',
          name: 'Admisión',
          component: {
          
            render (c) { 
              //lo mio martin el if de local  lo puse hoy 19 10 2022    localStorage.setItem('token', this.token())
              if(localStorage.getItem('id') && localStorage.getItem('token')){

              return c('router-view')
            
             }else{
              this.$router.push('/pages/login');
            
            }
          }
              
          },
          children: [
            // {
            //   path: 'cards',
            //   name: 'Cards',
            //   component: Cards
            // },
            {
              path: 'inicio',
              name: 'Inicio',
              component: inicio
            },
            {
              path: 'datosPersonales',
              name: 'Datos Personales',
              component: datosPersonales
            },
           
          
            {
              path: 'referenciaBancaria',
              name: 'Referencia Bancaria',      
              component: referenciaBancaria
            },
          
            {
              path: 'ceneval',
              name: 'Ceneval',
              component: ceneval
            },
            {
              path: 'ficha',
              name: 'Ficha',
              component: ficha
            },

            {
              path: 'cargaDocumentosAceptado',
              name: 'CargaDocumentosAceptado',
              component: cargaDocumentosAceptado
            },
            {
              path: 'referenciaInscripcion',
              name: 'ReferenciaInscripcion',
              component: referenciaInscripcion
            },
            {
              path: 'cargaMateriasInicio',
              name: 'CargaMateriasInicio',
              component: cargaMateriasInicio
            },
            // {
            //   path: 'collapses',
            //   name: 'Collapses',
            //   component: Collapses
            // },
            // {
            //   path: 'jumbotrons',
            //   name: 'Jumbotrons',
            //   component: Jumbotrons
            // },
            // {
            //   path: 'list-groups',
            //   name: 'List Groups',
            //   component: ListGroups
            // },
            // {
            //   path: 'navs',
            //   name: 'Navs',
            //   component: Navs
            // },
            // {
            //   path: 'navbars',
            //   name: 'Navbars',
            //   component: Navbars
            // },
            // {
            //   path: 'paginations',
            //   name: 'Paginations',
            //   component: Paginations
            // },
            // {
            //   path: 'popovers',
            //   name: 'Popovers',
            //   component: Popovers
            // },
            // {
            //   path: 'progress-bars',
            //   name: 'Progress Bars',
            //   component: ProgressBars
            // },
            // {
            //   path: 'tooltips',
            //   name: 'Tooltips',
            //   component: Tooltips
            // }
          ]
        }

        // {
        //   path: 'buttons',
        //   redirect: '/buttons/standard-buttons',
        //   name: 'Buttons',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'standard-buttons',
        //       name: 'Standard Buttons',
        //       component: StandardButtons
        //     },
        //     {
        //       path: 'button-groups',
        //       name: 'Button Groups',
        //       component: ButtonGroups
        //     },
        //     {
        //       path: 'dropdowns',
        //       name: 'Dropdowns',
        //       component: Dropdowns
        //     },
        //     {
        //       path: 'brand-buttons',
        //       name: 'Brand Buttons',
        //       component: BrandButtons
        //     }
        //   ]
        // },

        // {
        //   path: 'icons',
        //   redirect: '/icons/coreui-icons',
        //   name: 'CoreUI Icons',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'coreui-icons',
        //       name: 'Icons library',
        //       component: CoreUIIcons
        //     },
        //     {
        //       path: 'brands',
        //       name: 'Brands',
        //       component: Brands
        //     },
        //     {
        //       path: 'flags',
        //       name: 'Flags',
        //       component: Flags
        //     }
        //   ]
        // },

        // {
        //   path: 'notifications',
        //   redirect: '/notifications/alerts',
        //   name: 'Notifications',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'alerts',
        //       name: 'Alerts',
        //       component: Alerts
        //     },
        //     {
        //       path: 'badges',
        //       name: 'Badges',
        //       component: Badges
        //     },
        //     {
        //       path: 'modals',
        //       name: 'Modals',
        //       component: Modals
        //     }
        //   ]
        // }


      ]

     
    },

  
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },

        //ESTO LO COMENTE
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'forgot',
          name: 'Forgot',
          component: Forgot
        }
      ]
    }

  ]

}

